import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SubscriberChart from "../common/dashboard9/sourceCodeChart";
import {
  fetchLeakedCode,
  fetchLeakedCodeAction,
  fetchLeakedCodeGraph,
} from "../../actions/leakedCode";
import moment from "moment";
import Pagination from "../Pagination/Pagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TrialComponent from "../common/trialComponent";
import NoData from "../common/noDataComponetnt";
import ScanMessage from "../common/scanningMessage";
import AccessPage from "../Shared/accessPage";
import SideNav from "../resuableComponent/sidenav";
import Header from "../resuableComponent/header";
import Loader from "../resuableComponent/loader";
import {
  fetchSubDomains,
  addRemediationData,
  removeRemediationData,
} from "../../actions/sub-domain";
import BasicLineColumn from "../common/apexChart/lineChart";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import { API_URL } from "../../utils/constant";
import axios from "axios";



const SourceCodeLeakage = () => {
  const id = useSelector(
    (state) =>
      state?.summary?.localClient?._id || state?.summary?.clients?.data?.[0]?._id
  );
  const client = useSelector(
    (state) => state?.summary?.localClient || state?.summary?.clients?.data?.[0]
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const [filterData, setfilterData] = useState("all");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [PageSize, setPerPage] = useState(10);
  const [forValue, setForValue] = useState("forever");
  const [lookalikeAppId, setLookalikeAppId] = useState([]);
  const [actionNote, setActionNote] = useState("");
  const [action, setAction] = useState("");
  const [selectedValue, setSelectedValue] = useState("pleaseSelect");
  const [isModal, setModal] = useState("");
  const [category, setCategory] = useState("all");
  const [checkPath, setCheckPath] = useState('');
  const state = useSelector((state) => state?.leakedCode?.data);
  const modeValue = new URLSearchParams(location?.search).get('mode');
  const scanCompleted = useSelector(
    (state) => state?.leakedCode?.scanCompleted
  );
  const [addedRemediations, setAddedRemediations] = useState([]);

  const scanDetails = useSelector((state) => state?.leakedCode?.sourcecodegraph?.data);
  const clientType = useSelector(
    (state) =>
      state?.auth?.user?.accessLevel
  );
  let remediationList = useSelector(
    (state) => state?.auth?.remediationListAll?.data?.data
  );

  const cartDispatch = useDispatch();

  const removeToCart = (_id) => e => {
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to remove this from the Remediation List Panel?",
      type: "red",
      buttons: {
        ok: {
          text: "Remove",
          btnClass: "btn-danger",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            removeRemediationData(_id, cartDispatch, id).then(() => {
              fetchLeakedCode(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

  const addToCart = (row) => e => {
    let data = {
      "collectionName":'deltaleakedcodes',
      "organisationId":id,
      "recordId":row?._id,
    }
    $.confirm({
      title: "Are you sure?",
      content: "Do you really want to add this to the Remediation List Panel ?",
      type: "green",
      buttons: {
        ok: {
          text: "Add",
          btnClass: "btn-green",
          keys: ["enter"],
          action: function () {
            e.preventDefault();
            addRemediationData(data, cartDispatch).then(() => {
              fetchLeakedCode(id, dispatch, page, category);
            })
          },
        },
        cancel: function () {
          //console.log("the user clicked cancel");
        },
      },
    });
  }

 
  useEffect(() => {
    if (remediationList) {
      const updatedRemediations = remediationList.reduce((results, elem) => {
        return { ...results, [elem._id]: elem };
      }, {});
      setAddedRemediations(updatedRemediations);
    }
  }, [remediationList]);

  useEffect(()=>{
    setCheckPath(location.search)
  },[location.search])

  useEffect(() => {
    setTotalItems(state?.data?.totalLeakedCodes);
  }, [state?.data?.totalLeakedCodes]);

  let objArray = {};
  for (let count = 0; count < state?.length; count++) {
    let createdAt = state[count].createdAt.split("T")[0];
    if (objArray[createdAt]) {
      objArray[createdAt].push(state[count]);
    } else {
      objArray[createdAt] = [state[count]];
    }
  }

  let verifiedCode = [],
    unverifiedCode = [];
  let dateKey = [];
  Object.keys(objArray || {}).map((key) => {
    dateKey.push(key);
    let value = objArray[key];
    let verifiedData = value?.filter((val) => val?.isSuspicious);
    let unverifiedData = value?.filter((val) => !val?.isSuspicious);
    verifiedCode.push(verifiedData?.length || 0);
    unverifiedCode.push(unverifiedData?.length || 0);
  });

  useEffect(() => {
    fetchLeakedCode(id, dispatch, page, category,filterData);
  }, [id, page, category,filterData]);

  useEffect(() => {
    fetchLeakedCodeGraph(id, dispatch);
  }, [id, dispatch]);

  let mapData = state?.data?.data;

  let currentTableData = [];

  if (mapData && mapData.length > 0) {
    currentTableData = mapData;
  }

  const [containerClass, setContainerClass] = useState("data-container");
  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setContainerClass("data-container active");
      }, 300);
    }
  }, [state]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [multiCheckTrue, setMultiCheckTrue] = useState(false);

  const multicheck = (data) => {
    const allIds = data?.map((item) => item._id);
    if (selectedIds.length > 0 || selectedIds.length == 0) {
      setMultiCheckTrue(true);
      setSelectedIds(allIds);
    }
    if (multiCheckTrue == true) {
      setSelectedIds([]);
      setMultiCheckTrue(false);
    }
  };

  const singleClick = (id, data) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
    if (selectedIds.length > 10) {
      setMultiCheckTrue(true);
    } else {
      setMultiCheckTrue(false);
    }
  };

  const addModal = () => {
    setModal("Form Wizard modal");
  };

  const Unsnoozed = (_id) => {
    let ids = [];
    ids.push(_id);
    const data = {
      action: "resetAction",
      leakedCodeIds: selectedIds == 0 ? ids : selectedIds,
    };
    fetchLeakedCodeAction(id, dispatch, page, category, data);
    setSelectedIds([]);
  };

  const submit = () => {
    const data = {
      action: action,
      leakedCodeIds: selectedIds == 0 ? lookalikeAppId : selectedIds,
      actionNote: actionNote,
      for: forValue,
    };
    fetchLeakedCodeAction(id, dispatch, page, category, data);
    setModal("");
    setSelectedValue("pleaseSelect");
    setActionNote("");
    setSelectedIds([]);
  };

  const snooze = (_id, event) => {
    if (event == "") {
    }

    if (event == "riskAccepted") {
    }

    if (event == "markAsFalsePositive") {
    }

    if (event == "markAsRemidiated") {
      let ids = [];
      ids.push(_id);
      const data = {
        action: "markAsRemidiated",
        leakedCodeIds: selectedIds == 0 ? ids : selectedIds,
        actionNote: "",
        for: "forever",
      };
      fetchLeakedCodeAction(id, dispatch, page, category, data);
      setSelectedValue("pleaseSelect");
      setActionNote("");
      setSelectedIds([]);
    }
  };

  
  const downloadFile = async () => {
    try {
      const session = localStorage.getItem("session");
      axios.defaults.headers.common["Authorization"] = `Bearer ${session}`;
      const response = await axios.get(
        `${API_URL}/api/approved-code?excel=true&orgId=${id}`,
        {
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SourceCode Details.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {/* <Header type="breach" />
            <SideNav type="breach" /> */}

      {state ? (
        <div className={containerClass}>
          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9">
              <div className="row_boxes">
                <div className="gradinet-effect w-100">
                  {scanDetails && (
                    <BasicLineColumn
                    dates={scanDetails?.dates}
                    ipArray={scanDetails?.scanDetails}
                      color={["#008ffb", "#00e396"]}
                      yAxis="Source Code Leakage"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix no_margin" style={{ marginTop: "20px" }}>
            <div className="col-sm-2"></div>
            <div className="col-sm-9" style={{ padding: "0" }}>
            <div className="row mb-2">
                  <div className="col-lg-4 col-md-12">
                    <select
                      className="custom-select custom-select-sm form-control form-control-sm"
                      value={filterData}
                      onChange={(e) => {
                        setfilterData(e.target.value);
                        setPage(1);
                      }}
                    >
                      <option value="all">All</option>
                      <option value="verified">Verified</option>
                      <option value="unverified">Unverified</option>
                    </select>
                  </div>
                  <div className="col-lg-4">
                    </div>
                {modeValue == "report-mode" && <>
                  <div className="col-md-4 col-sm-12 text-right hidden-xs">
              {client?.subscriptionType != "trial" && currentTableData?.length !== 0 && (
                <span>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile();
                    }}
                    style={{
                      float: "right",
                      color: "black",
                      fontWeight: "bold",
                    }}
                    href="/add-report"
                    className="btn btn-primary mr-1"
                  >
                    <i
                      className="fa fa-file-excel-o"
                      style={{ color: "black" }}
                    ></i>
                  </a>
                </span>
              )}
            </div>
            </>}
                  </div>
              <div className="row_boxes row_boxes_table">
                <div className="table_box">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Platform</th>
                        <th>Repository Link</th>
                        <th>Last Update at</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentTableData?.map((res, index) => {
                          const pageCount = (page - 1) * 10 + 1;
                        return (
                          <tr key={index}>
                          <td style={{ whiteSpace: "unset" }}>
                            <span>{pageCount + index}</span>
                          </td>
                          <th>
                            {" "}
                            <span
                              className={
                                res?.isSuspicious
                                  ? "badge badge-success"
                                  : "badge badge-danger"
                              }
                            >
                              {res?.isSuspicious ? "Verified" : "Unverified"}
                            </span>
                            {res?.isLinkDown && (
                              <span className="badge badge-warning">
                                Link down
                              </span>
                            )}
                          </th>
                          <td style={{ whiteSpace: "unset" }}>GitHub</td>
                          <td style={{ whiteSpace: "unset" }}>
                            <a href={`${res?.repoUrl}`} target="_blank">
                              {res?.repoUrl}
                            </a>
                          </td>
                          <td>
                            {" "}
                            {res?.updatedAt
                              ? moment(res?.updatedAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : res?.createdAt
                              ? moment(res?.createdAt).format(
                                  "DD-MM-YYYY HH:MM"
                                )
                              : "NA"}
                          </td>
                          {checkPath == '?mode=assessment-mode' && clientType == "Admin" ?
                          <td>
                            {res?.isTicketChecked == false ? (
                              <button
                                onClick={addToCart({
                                  ...res,
                                  remediationType: "Source Code Leakage",
                                })}
                              >
                                <span className="fa fa-plus action_icon"></span>
                              </button>
                            ) : (
                              <button onClick={removeToCart(res?._id)}>
                                <span className="fa fa-minus action_icon"></span>
                              </button>
                            )}
                          </td>
                          : null}
                        </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="dataTables_paginate">
                {totalItems > 0 && (
                  <Pagination
                    className="pagination-bar"
                    currentPage={page}
                    totalCount={totalItems}
                    pageSize={PageSize}
                    onPageChange={(page) => setPage(page)}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={`modal fade Form-Wizard-modal ${
              isModal === "Form Wizard modal" ? "d-block show" : ""
            }`}
          >
            <div className="modal-dialog modal-lg" id="clientModal">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {action == "markAsFalsePositive"
                      ? "Mark As False Positive"
                      : "Risk Accepted"}
                  </h5>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal("");
                      setActionNote("");
                    }}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body pricing_page modal_mob">
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="card">
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <label className="col-lg-6 col-md-12">
                            Snooze for
                          </label>
                          <div className="form-group ol-lg-12 col-md-12">
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={(e) => setForValue(e.target.value)}
                            >
                              <option value="forever">Forever</option>
                              <option value="3 months">3 Months</option>
                              <option value="6 months">6 Months</option>
                              <option value="12 months">12 Months</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="col-lg-12 col-md-12">Detail</label>
                          <div className="form-group col-lg-12 col-md-12">
                            <textarea
                              onChange={(e) => setActionNote(e.target.value)}
                              value={actionNote}
                              className="form-control"
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-12"
                          style={{ marginRight: "28px" }}
                        >
                          <button
                            type="button"
                            style={{ color: "black", fontWeight: "bold" }}
                            onClick={submit}
                            className="btn btn-round btn-primary mr-1 pull-right"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => {
                              setModal("");
                              setActionNote("");
                            }}
                            className="btn btn-round btn-default pull-right"
                            style={{ marginRight: "10px" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SourceCodeLeakage;
